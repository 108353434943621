import Vue from "vue";
import Router from "vue-router";
import { checkLineUser } from "@/config/line-service";
import { checkUserExist } from "./config/login-service";
import { loginLineUser } from "@/config/line-service-login";
import store from "../src/store/";
import { logVisit } from "@/config/general-function";
// import i18n from "./plugins/i18n";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        // ========== ========== Page Login ========== ==========
        {
          name: "check-login",
          path: "/",
          component: () => import("@/layouts/blank-layout/Blanklayout"),
          beforeEnter: checkLineUser,
          meta: {
            roleUser: [""],
            visitLog: {},
          },
        },
        {
          name: "login",
          path: "login",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/LoginWithOutOtp"),
          beforeEnter: loginLineUser,
          meta: {
            roleUser: [""],
            visitLog: {},
          },
        },
        {
          name: "login-line",
          path: "login-line",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/LoginLine"),
          beforeEnter: loginLineUser,
          meta: {
            roleUser: [""],
            visitLog: {},
          },
        },
        {
          name: "login-bypass",
          path: "login-bypass",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/CheckNumberOtp"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "login-user",
          path: "login-user",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/LoginWithOutOtp"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },

        // ========== ========== Page Register ========== ==========
        {
          name: "register-bypass",
          path: "register-bypass",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/RegisterPhone"),
          meta: {
            roleUser: [""],
            visitLog: {},
          },
        },

        // ========== ========== Page Check Number ========== ==========
        {
          name: "checknumber",
          path: "checknumber",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/CheckNumber"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "checknumberbyotp",
          path: "checknumberbyotp",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/CheckNumberOtp"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "checknumberLine",
          path: "checknumberLine",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/CheckNumberLine"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "checknumberGoogle",
          path: "checknumberGoogle",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/CheckNumberGoogle"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },

        // ========== ========== Page Detail User ========== ==========
        {
          name: "detail-user",
          path: "detail-user",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/DetailUser"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "detail-user-otp",
          path: "detail-user-otp",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/DetailUserOtp"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },

        // ========== ========== Page For Main ========== ==========
        {
          name: "search",
          path: "search",
          component: () => import("@/components/searchPage"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },

        // ========== ========== Page For Mission ========== ==========
        {
          name: "activity-coming",
          path: "activity-coming",
          component: () =>
            import("@/components/activityComing/activityComing.vue"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "activity-recommended",
          path: "activity-recommended",
          component: () =>
            import("@/components/activityRecommended/activityRecommended.vue"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "mission-detail",
          path: "missiondetail/:id/:status",
          component: () => import("@/views/Customer/Mission/MissionDetail"),
          beforeEnter: async (to, from, next) => {
            if (to.params.status == "campaign") {
              const userExist = await checkUserExist(to);
              if (userExist) {
                next();
              } else {
                next({ name: "check-login" });
              }
            } else {
              next();
            }
          },
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "mission_id",
                status: "mission_status",
              },
            },
          },
        },
        {
          name: "mission-upload",
          path: "missionupload/:id",
          component: () => import("@/views/Customer/Mission/MissionUpload"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "employee_mission_id"
              },
            },
          },
        },
        {
          name: "mission-checkin",
          path: "missioncheckin/:id",
          component: () => import("@/views/Customer/Mission/MissionCheckIn"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "employee_mission_id"
              },
            },
          },
        },
        {
          name: "mission-survey",
          path: "missionsurvey/:id",
          component: () => import("@/views/Customer/Mission/MissionSurvey"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "employee_mission_id"
              },
            },
          },
        },
        {
          name: "mission-lucky-draw",
          path: "missionluckydraw/:id",
          component: () => import("@/views/Customer/Mission/MissionLuckyDraw"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "employee_mission_id"
              },
            },
          },
        },
        {
          name: "my-mission-detail",
          path: "mymission/detail/:id",
          component: () => import("@/views/Customer/Mission/MyMissionDetail"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "employee_mission_id",
                status: "mission_status",
              },
            },
          },
        },

        // ========== ========== Page For My Account ========== ==========
        {
          name: "my-history",
          path: "history",
          component: () => import("@/views/Customer/History/MyHistory"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("@/views/Customer/Setting/Privacy"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "favorite-item",
          path: "favorite-item",
          component: () => import("@/views/Customer/Favorite/FavoritePage"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "list-address",
          path: "list-address",
          component: () => import("@/views/Customer/Address/ListAddress"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "add-address",
          path: "add-address",
          component: () => import("@/views/Customer/Address/AddAddress"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "my-coupons",
          path: "my-coupons",
          component: () => import("@/views/Customer/MyCoupons/MyCoupons"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "history-coupon",
          path: "history-coupon",
          component: () => import("@/views/Customer/MyCoupons/HistoryMyCoupon"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "history-code",
          path: "history-code",
          component: () => import("@/views/Customer/History/HistoryCode"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },

        // ========== ========== Page For Blog Reward Shipment Other ========== ==========
        {
          name: "all-blog",
          path: "all-blog",
          component: () => import("@/components/allBlog.vue"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "blog",
          path: "blog/:id",
          component: () => import("@/views/Customer/Blog/Blog"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "blog_id",
              },
            },
          },
        },
        {
          name: "reward-so-hot",
          path: "reward-so-hot",
          component: () => import("@/views/Customer/Reward/RewardSoHot"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "reward/list-address",
          path: "reward/list-address",
          component: () => import("@/views/Customer/Reward/ListAddress"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "cancel-shipment",
          path: "cancel-shipment",
          component: () => import("@/views/Customer/Transport/CancelPage"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },

        // ========== ========== Page For Friend ========== ==========
        {
          name: "invite-friend",
          path: "invite-friend",
          component: () => import("@/views/Customer/Friend/Invite"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "friend-request",
          path: "friend-request",
          component: () => import("@/components/friend/listRequestFriend.vue"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "friend-list",
          path: "friend-list",
          component: () => import("@/components/friend/listFriend.vue"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "friend-recommend",
          path: "friend-recommend",
          component: () =>
            import("@/components/friend/listRecommendFriend.vue"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "friend-profile",
          path: "friend-profile/:id/:user_id",
          component: () => import("@/components/friend/profileFriend.vue"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                id: "friend_id",
                user_id: "user_id",
              },
            },
          },
        },

        // ========== ========== Page Not Found ========== ==========
        {
          name: "page-not-found",
          path: "/",
          component: () => import("@/views/PageNotFound"),
          meta: {
            roleUser: [""],
            visitLog: {},
          },
        },
      ],
    },
    {
      path: "",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "main",
          path: "main",
          component: () => import("@/views/Customer/Main"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "mission",
          path: "mission",
          component: () => import("@/views/Customer/Mission/Mission"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "my-mission",
          path: "mymission/:status",
          component: () => import("@/views/Customer/Mission/MyMission"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {
              params: {
                status: "mission_status",
              },
            },
          },
        },
        {
          name: "reward",
          path: "reward",
          component: () => import("@/views/Customer/Reward/RewardPage"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "transport",
          path: "transport",
          component: () => import("@/views/Customer/Transport/TransportPage"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
        {
          name: "my-account",
          path: "account",
          component: () => import("@/views/Customer/MyAccount/MyAccount"),
          meta: {
            roleUser: ["Customer"],
            visitLog: {},
          },
        },
      ],
    },

    // ========== ========== Page Not Found ========== ==========
    {
      path: "/microsite",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "microsite-login",
          path: ":slug",
          component: () => import("@/views/Microsite/user/Login"),
          beforeEnter: clearMicrositeUserState,
          meta: {
            visitLog: {
              params: {
                slug: "slug",
              },
            },
          },
        },
        {
          name: "microsite-register",
          path: ":slug/register",
          component: () => import("@/views/Microsite/user/Register"),
          meta: {
            micrositeRole: ["Customer"],
            visitLog: {
              params: {
                slug: "slug",
              },
            },
          },
        },
        {
          name: "microsite-thankyou",
          path: ":slug/thankyou",
          component: () => import("@/views/Microsite/user/Thankyou"),
          meta: {
            micrositeRole: ["Customer"],
            visitLog: {
              params: {
                slug: "slug",
              },
            },
          },
        },
        {
          name: "microsite-menu",
          path: ":slug/menu",
          component: () => import("@/views/Microsite/user/Menu"),
          meta: {
            micrositeRole: ["Customer"],
            visitLog: {
              params: {
                slug: "slug",
              },
            },
          },
        },
        {
          name: "microsite-history",
          path: ":slug/history",
          component: () => import("@/views/Microsite/user/History"),
          meta: {
            micrositeRole: ["Customer"],
            visitLog: {
              params: {
                slug: "slug",
              },
            },
          },
        },
        {
          name: "microsite-top-spender",
          path: ":slug/top-spender",
          component: () => import("@/views/Microsite/user/TopSpender"),
          meta: {
            micrositeRole: ["Customer", "TopSpenderCustomer"],
            visitLog: {
              params: {
                slug: "slug",
              },
            },
          },
        },
      ],
    },
    {
      name: "microsite-end",
      path: "/microsite/:slug/end",
      component: () => import("@/views/Microsite/user/End"),
      meta: {
        visitLog: {
          params: {
            slug: "slug",
          },
        },
      },
    },
    {
      name: "microsite-pause",
      path: "/microsite/:slug/pause",
      component: () => import("@/views/Microsite/user/Pause"),
      meta: {
        visitLog: {
          params: {
            slug: "slug",
          },
        },
      },
    },
    {
      name: "microsite-error",
      path: "/microsite/error",
      component: () => import("@/views/Microsite/Error"),
      meta: {
        visitLog: {},
      },
    },
    {
      path: "*",
      name: "error",
      component: () => import("@/views/Error"),
      meta: {
        roleUser: [""],
        visitLog: {},
      },
      beforeEnter: (to, from, next) => {
        next({ name: "check-login" });
      },
    },
  ],
});

function clearMicrositeUserState(_to, _from, next) {
  store.commit("microsite/CLEAR_USER_STATE");
  return next();
}

router.beforeEach(async (to, from, next) => {
  logVisit(to, store.state.defaultSystem);
  if (to.path.includes("microsite")) {
    // 1) Validate Campaign
    if (to.name === "microsite-error") {
      return next();
    }
    if (to.name === "microsite-login" || to.name === "microsite-register") {
      await store.dispatch("microsite/getSiteTemplate");
      await store.dispatch("microsite/getCampaign", to.params.slug);
    }

    // status campaign
    const statusCampaign = await store.dispatch(
      "microsite/checkCampaignStatus"
    );

    //check if campaign is survey and status is active and route is not register
    if (
      store.state.microsite.campaign.template.name === "Survey" &&
      statusCampaign == "active" &&
      to.name !== "microsite-register" &&
      to.name !== "microsite-thankyou"
    ) {
      next({
        name: "microsite-register",
        params: { slug: to.params.slug },
      });
      return;
    }
    //check if campaign is survey and status is active and route is register
    else if (
      store.state.microsite.campaign.template.name === "Survey" &&
      statusCampaign == "active" &&
      to.name === "microsite-register"
    ) {
      next();
      return;
    } else if (
      store.state.microsite.campaign.template.name !== "Survey" &&
      statusCampaign == "active" &&
      to.name === "microsite-register"
    ) {
      next();
      return;
    } else if (to.name === "microsite-thankyou" && statusCampaign == "active") {
      next();
      return;
    } else if (to.name === "microsite-menu" && statusCampaign == "active") {
      next();
      return;
    } else if (to.name === "microsite-history" && statusCampaign == "active") {
      next();
      return;
    } else if (
      to.name === "microsite-top-spender" &&
      statusCampaign == "active"
    ) {
      next();
      return;
    }
    //check if status is active and route is not login
    else if (statusCampaign == "active" && to.name !== "microsite-login") {
      next({
        name: "microsite-login",
        params: { slug: to.params.slug },
      });
      return;
    }
    // check if status is pause and route is not pause
    else if (statusCampaign == "pause" && to.name !== "microsite-pause") {
      next({
        name: "microsite-pause",
        params: { slug: to.params.slug },
      });
      return;
    }
    // check if status is not start and route is not end
    else if (statusCampaign == "not_start" && to.name !== "microsite-pause") {
      next({
        name: "microsite-pause",
        params: { slug: to.params.slug },
      });
      return;
    }
    // check if status is end and route is not end
    else if (statusCampaign == "end" && to.name !== "microsite-end") {
      next({
        name: "microsite-end",
        params: { slug: to.params.slug },
      });
      return;
    }

    //if status is not pause and route is pause
    if (
      to.name === "microsite-pause" &&
      statusCampaign != "pause" &&
      statusCampaign != "not_start"
    ) {
      next({
        name: "microsite-login",
        params: { slug: to.params.slug },
      });
      return;
    }

    // // 2) Validate User Roles
    if (to.meta.micrositeRole && to.meta.micrositeRole.includes("Customer")) {
      if (!store.state.microsite.user) {
        next({
          name: "microsite-login",
          params: { slug: to.params.slug },
        });
        return;
      } else if (to.meta.micrositeRole.includes("TopSpenderCustomer")) {
        if (
          store.state.microsite.campaign.template.name !==
          "Bill-luckydraw-top-spender"
        ) {
          next({ name: "microsite-error" });
          return;
        }
      }
    }

    return next();
  }

  if (to.params.status == "campaign") {
    store.commit("login/SET_URL_REDIRECT", { urlRedirect: to.path });
    store.commit("defaultSystem/SET_USER", { role: "Customer" });
    store.commit("friend/SET_INVITE", to.query.invite ?? "");
    store.commit("friend/SET_PLATFORM", to.query.platform);
  }

  let role = store.state.defaultSystem.user;
  let roleName = "";
  if (role == null) {
    roleName = "Unknown";
  } else {
    roleName = role.role;
  }

  if (!to.meta.roleUser[0]) {
    return next();
  }

  let checkRoleRoutes = to.meta.roleUser
    ? to.meta.roleUser.includes(roleName)
    : next({ name: "check-login" });

  if (checkRoleRoutes) {
    return next();
  } else {
    return next({ name: "check-login" });
  }
});

export default router;
