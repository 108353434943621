const state = {
  mobile: "",
  urlRedirect: "",
};

const mutations = {
  SET_MOBILE(state, payload) {
    state.mobile = payload.mobile;
  },
  SET_URL_REDIRECT(state, payload) {
    state.urlRedirect = payload.urlRedirect;
  },
};

const getters = {
  getMobile: (state) => state.mobile,
  getUrlRedirect: (state) => state.urlRedirect,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
