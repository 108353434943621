import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import defaultSystem from "./modules/default";
import mission from "./modules/mission";
import login from "./modules/login";
import address from "./modules/address";
import deal from "./modules/deal";
import reward from "./modules/reward";
import friend from "./modules/friend";
import partner from "./modules/partner";
import banner from "./modules/banner";
import microsite from "./modules/microsite";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})


export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  // strict: true,
  modules: {
    defaultSystem,
    mission,
    login,
    address,
    deal,
    reward,
    friend,
    partner,
    banner,
    microsite,
  },
});
