const state = {
    invite: "",
    platform: "",
}

const mutations = {
    SET_INVITE(state, payload) {
        state.invite = payload
    },
    SET_PLATFORM(state, payload) {
        state.platform = payload
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};