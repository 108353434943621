import store from "@/store";
import Axios from "@/config/backend-service";

export async function checkUserExist(to) {
  return new Promise((resolve) => {
    store.commit("login/SET_URL_REDIRECT", { urlRedirect: to.path });
    const platform = to.query.platform === undefined ? "" : to.query.platform;
    if (platform) {
      store.commit("friend/SET_PLATFORM", to.query.platform);
      countPlatform(
        to.query.platform,
        store.state.login.mobile ||
          store.state.defaultSystem?.userLine?.userId ||
          null,
        to.params.id
      );
    }
    resolve(
      store.state.login.mobile ||
        store.state.defaultSystem?.userLine?.userId ||
        null
    );
  });
}

function countPlatform(platform, customer, mission_id) {
  Axios.post("site/countPlatform", {
    platform: platform,
    customer: customer,
    mission_id: mission_id,
  });
}
