import MicrositeAxios from "@/config/microsite-service";
import axios from "axios";
import router from "@/router";

const state = {
  campaign: null,
  user: null
};

const mutations = {
  SET_CAMPAIGN(state, payload) {
    state.campaign = payload.campaign;
  },
  SET_USER(state, payload) {
    state.user = payload.user;
  },
  CLEAR_USER_STATE(state) {
    state.user = null;
  }
};

const getters = {
  getCampaign(state) {
    return state.campaign;
  },
  getUser(state) {
    return state.user;
  }
};

const actions = {
  async getSiteTemplate({ commit }) {
    try {
      const response = await axios.get(
        process.env.VUE_APP_API_SERVICE + "/site/getSiteTemplate",
        {
          params: {
            url: " https://" + window.location.hostname
          }
        }
      );
      commit("defaultSystem/SET_SITE", response.data.data, { root: true });
    } catch (error) {
      console.error(error);
    }
  },
  async getCampaign({ commit }, campaignSlug) {
    try {
      const siteId = this.state.defaultSystem.site.id;

      const response = await MicrositeAxios({
        method: "get",
        url: `getCampaign/${siteId}?slug=${campaignSlug}`
      });
      if (!response.data.data.microsite) router.push({ name: "error" });
      commit("SET_CAMPAIGN", {
        campaign: response.data.data.microsite
      });
    } catch (error) {
      console.log(error);
    }
  },
  async checkCampaignStatus() {
    try {
      const siteId = this.state.defaultSystem.site.id;
      const campaignId = this.state.microsite.campaign.id;

      const response = await MicrositeAxios({
        method: "get",
        url: `checkCampaignStatus/${siteId}?campaign=${campaignId}`
      });

      return  response.data.data.status;
    } catch (error) {
      console.error(error);
    }
  },
  async checkCampaignIsOngoing() {
    try {
      const siteId = this.state.defaultSystem.site.id;
      const campaignId = this.state.microsite.campaign.id;

      const response = await MicrositeAxios({
        method: "get",
        url: `checkCampaignIsOngoing/${siteId}?campaign=${campaignId}`
      });
      return response.data.data.isOngoing;
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
